
import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
import ApiLogistics from "@/request/apis/api_logistics";
import router from "@/router";
import {message} from "ant-design-vue/es";
import StorageUtil from "@/utils/storage_util";
import ImageView from '@/components/ImageView.vue'
import DriverSet from '@/components/DriverSet.vue'
import PageUtil from "@/utils/page_util";
import {IDriverItem, IEditDriver} from "@/models/logistics_member";
import {amountFormat} from "@/utils/common";
import {ILicenceItem} from "@/models/logistics_model";
import ApiLogisticsAudit from "@/request/apis/api_logistics_audit";

interface IState {
  list: IDriverItem[],
  total: number;
  loading: boolean;
  searches: any
  visible: boolean
  editItem?: IEditDriver
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '头像',
    dataIndex: 'head_img',
    key: 'head_img',
  },
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
  }, {
    title: '性别',
    dataIndex: 'gender',
    key: 'gender',
  },
  {
    title: '电话',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: '总收益(元)',
    dataIndex: 'income',
    key: 'income',
    align: 'right'
  },
  {
    title: '余额(元)',
    dataIndex: 'balance',
    key: 'balance',
    align: 'right'
  },{
    title: '数据',
    dataIndex: 'favorites_num',
    key: 'favorites_num',
    align: 'right'
  },
  {
    title: '简介',
    dataIndex: 'introduction',
    key: 'introduction',
    width: 230
  },
  {
    title: '认证信息',
    key: 'approve',
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    key: 'create_time',
  },
  {
    title: '接单状态',
    dataIndex: 'idle',
    key: 'idle',
  },
  {
    title: '状态',
    dataIndex: 'disable',
    key: 'disable',
  },
  {
    title: '操作',
    key: 'action',
    width: 250
  },
];

export default defineComponent({
  name: 'DriverList',
  components: {ImageView, DriverSet},
  setup() {
    const state: IState = reactive({
      list: [],
      total: 0,
      loading: false,
      editItem: undefined,
      visible: false,
      searches: {
        page_no: PageUtil.pageNo,
        page_size: PageUtil.pageSize,
        idle: '', // 司机是否空闲 0忙碌
        vehicle_type: '', // 车辆类型
        disable: '',
        vali_real_name: '',// 实名认证
        vali_driver: '',// 驾驶证认证
      },
    })


    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page_no,
      pageSize: state.searches.page_size,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否显示pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.page_size = pages.pageSize
      state.searches.page_no = pages.current
      getList()
    };

    //获取列表
    const getList = async () => {
      let params = {...state.searches}
      StorageUtil.setParams(params)
      state.loading = true
      const res = await ApiLogistics.getDriverList(params)
      state.loading = false
      if (res && res.error_code === 0) {
        state.total = res.data?.count || 0
        state.list = res.data?.list || []
      }
    }

    // 获取车辆类型


    // 设置modal显示隐藏
    const setVisible = (item: IDriverItem | undefined, flg: boolean) => {
      console.log(item)
      state.visible = flg
      if (item && flg) {
        state.editItem = {
          id: item.id,
          name: item.name,
          gender: item.gender,
          phone: item.phone,
          vehicle_type: item.vehicle_type,
          lng: item.lng,
          lat: item.lat,
          idle: Boolean(item.idle),
          disable: Boolean(item.disable),
          head_img: item.head_img,
        }
      } else {
        setTimeout(() => state.editItem = undefined, 300)
      }
    } 

    const onClose = () => {
      state.visible = false
      setTimeout(() => state.editItem = undefined, 300)
      getList()
    }

    // 设置启用禁用
    const onSetStatus = async (item: IDriverItem) => {
      state.loading = true
      const res = await ApiLogistics.setDriverDisable({
        "id": item.id,
        "disable": Number(item.disable === 0),
      })
      state.loading = false
      setVisible(item, false)
      if (res && res.error_code === 0) {
        message.success('操作成功')
        await getList()
      } else {
        message.error(res?.msg || '审核失败');
      }
    }

    // 上传车辆信息
    const updateDriver = async (item: IDriverItem) => {
      const res = await ApiLogisticsAudit.updateDriver(item.id)
      if (res && res.error_code === 0) {
        message.success('操作成功')
        await getList()
      } else {
        message.error(res?.msg || '上报失败');
      }
    }

    // 搜索
    const onSearch = (key: string, value: any, e: any) => {
      state.searches[key] = value
      state.searches.page_no = 1
      state.searches.page_size = PageUtil.pageSize
      getList()
    }

    // 重置search参数
    const resetSearch = () => {
      for (let key in state.searches) {
        state.searches[key] = ''
      }
      state.searches.page_no = 1
      state.searches.page_size = PageUtil.pageSize
      getList()
    }

    // 初始化search参数
    const initSearch = () => {
      const res = StorageUtil.getParams()
      if (res) {
        state.searches = {...state.searches, ...res}
      }
    }

    onMounted(() => {
      initSearch()
      getList()
    })

    return {
      ...toRefs(state),
      columns,
      pagination,
      handleTableChange,
      onSearch,
      resetSearch,
      onSetStatus,
      setVisible,
      onClose,
      amountFormat,
      updateDriver
    }
  }
})
